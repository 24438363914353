<template>
  <v-card>
    <p class="font-weight-medium pt-3 px-3">Change Password</p>
    <v-form ref="changePasswordForm">
      <v-row no-gutters class="px-3">
        <v-col cols="12">
          <v-text-field
            :rules="[rules.required]"
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            label="Old Password"
            v-model="oldPassword"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="newPassword"
            :rules="[rules.required]"
            :append-icon="newShowPassword ? 'visibility_off' : 'visibility'"
            :type="newShowPassword ? 'text' : 'password'"
            @click:append="newShowPassword = !newShowPassword"
            label="New Password"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="pa-3 px-3 action-bottom-border lightBlack">
      <v-spacer></v-spacer>
      <v-btn
        :loading='isUpdating'
        @click="updatePassword"
        color="primary"
        depressed
        small
      >Update Password
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UserService from '@/services/UserService'

export default {
  data () {
    return {
      isUpdating: false,
      showPassword: false,
      newShowPassword: false,
      oldPassword: '',
      newPassword: '',
      rules: {
        required: value => !!value || 'This field is required.'
      }
    }
  },
  methods: {
    updatePassword () {
      if (this.$refs.changePasswordForm.validate()) {
        this.isUpdating = true
        UserService.updatePassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        })
          .then(res => {
            this.$refs.changePasswordForm.reset()
            this.$swal({
              type: 'success',
              text: 'Successfully updated the password.',
              onOpen: () => {
                document.activeElement.blur()
              }
            })
          })
          .catch((error) => {
            this.$swal({
              type: 'error',
              text: error.response.data,
              showConfirmButton: false,
              timer: 2000
            })
          })
          .finally(() => {
            this.isUpdating = false
          })
      }
    }
  }
}
</script>

<style>

</style>
